import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class CapitalReportService{
    getAccountWiseReport(param){
        const url = `api/capital/report/account-wise/filter`
        const params ={
                'page-index':param.pageIndex,
                'session_year':param.session_years, 
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getAccountWiseExcel(param){
        const url = `api/capital/report/account-wise/excel`
        const params ={
                'page-index':param.pageIndex,
                'session_year':param.session_years, 
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.getFile(url,headers,params);
    }
    getTransactionWiseReport(param){
        const url = `api/capital/report/transaction-wise/filter`
        const params ={
                'page-index':param.pageIndex,
                'account':param.account,
                'branch':param.branch,
                'type':param.type,
                'today': param.today,
                'month':param.month,
                'year':param.year,
                'from':param.from,
                'session_year':param.session_years, 
                'to':param.to,
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getTransactionWiseExcel(param){
        const url = `api/capital/report/transaction-wise/excel`
        const params ={
                'page-index':param.pageIndex,
                'account':param.account,
                'branch':param.branch,
                'type':param.type,
                'today': param.today,
                'month':param.month,
                'year':param.year,
                'from':param.from,
                'session_year':param.session_years, 
                'to':param.to,
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.getFile(url,headers,params);
    }
}

export default new CapitalReportService();