<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                   Capital Report
                </li>
                 <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Account Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Capital Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download" @click="download"></i>
          </div>
        </div>
      </div>
      <!-- Col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select v-model="params.pageIndex" @change="searchFilter" class="mg-t-5 mr-2">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select class="mg-t-5 mr-2" v-model="params.session_year">
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}/{{ parseInt(year.substring(year.length - 2)) + 1 }}
            </option>
          </select>
          <input
            type="text"
            v-model="params.searched"
            class="filter-input mg-t-5 mr-2"
            placeholder="Search..."
            @keyup="searchKeyword"
          />
          <div class="float-right mr-3 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/general-report/capital">Account Wise</option>
              <option value="/general-report/capital/transaction-wise">Transaction Wise</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-theam-secondary table-search-head">
      <div>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Opening Capital:  {{ parseDigitForSlip(capitalDataAttributes.opening_balance) }}</span
        >
         <span class="font-s tx-bold mr-2 tx-primary"
          >Total Added Capital:  {{ parseDigitForSlip(capitalDataAttributes.added_capital) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Withdrawal Capital:  {{ parseDigitForSlip(capitalDataAttributes.withdrawal_capital) }}</span
        >
         <span class="font-s tx-bold mr-2 tx-primary"
          >Total Net Capital:  {{ parseDigitForSlip(capitalDataAttributes.total_net_capital) }}</span
        >
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-55p">Account Name</th>
              <th class="wd-20p">Opening Balance</th>
              <th class="wd-20p">Closing Balance</th>
            </tr>
          </thead>
          <tbody v-if="!loading && accountWiseDatas.length > 0">
            <tr v-for="(capital,index) in accountWiseDatas" :key="index">
              <th scope="row" class="table-start-item">{{ index+pageSerialNo }}.</th>
              <th>{{ capital.account_name }}</th>
              <td>{{ parseDigitForSlip(capital.opening_balance) }}</td>
              <td>{{ parseDigitForSlip(capital.closing_balance) }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" class="text-center">{{error_message}}</td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && accountWiseDatas == 0">
            <tr>
              <td colspan="11" class="text-center">No Records Found.</td>
            </tr>
          </tbody>
           <tbody v-else-if="loading">
            <tr>
              <td colspan="11" class="text-center">Loading...</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import _ from "lodash";
export default {
  components: {

  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "dataLists2",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("capitalReports",["accountWiseDatas","capitalDataAttributes"])
  },
  data() {
    return {
      sessionYears: ["2021"],
      route: this.$route.path,
      params:{
          pageIndex:15,
          session_year:2021,
          searched:'',
          offset:0,
      },
      loading:false,
      error:false,
      error_message:'',
      page:1,
      pageSerialNo:1,
      totalPageCount:'',
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    print(){
      window.print();
    },
    filterReportType() {
      this.$router.push(this.route);
    },
    getData() {
      this.loading = true;
      Services.getAccountWiseReport(this.params).then(res=>{
          this.$store.commit("capitalReports/setAccountWiseDatas",res.data.data.data);
          this.$store.commit("capitalReports/setCapitalDataAttributes",res.data.data.attributes);
          this.totalPageCount = res.data.data.total_no_of_pages;
      }).catch(err=>{   
           this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error fetching data from the server.";
            }
          }
      }).finally(()=>{
          this.loading =false;
      });
    },
     searchFilter(){
        this.params.page = 1;
        this.params.offset = 0;
        this.params.pageSerialNo = 1;
        this.getData();
    },
    searchKeyword:_.debounce(function(){
        this.params.page = 1;
        this.params.offset = 0;
        this.params.pageSerialNo = 1;
        this.getData();
    },1500),
     download() {
      Services.getAccountWiseExcel(this.params)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          const elink = document.createElement("a");
          elink.download = "capital-account-wise-report.xlsx";
          elink.style.display = "none";
          elink.style.font = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>